import { useNavigate } from "react-router-dom";
import PLAY_STING from "../../../assets/image/Popup/coinsting.png";
import PLAY from "../../../assets/image/Popup/play.png";

import COIN from "../../../assets/image/coin.png";
import CODE from "../../../assets/image/Popup/code.png";
import {
  Campaign,
  ListReceipt,
  LuckyDrawList,
} from "../../../models/GameModel";
import { setAuthorization } from "../../../services/apiService/configURL";
import { homeServices } from "../../../services/apiService/homeService";
import {
  gameLocal,
  userLocal,
} from "../../../services/localService/localService";
import { Fragment } from "react/jsx-runtime";
import ContactPhoneZalo from "../../../components/ContactPhoneZalo/ContactPhoneZalo";
import { useEffect, useState } from "react";
import LoadingButton from "../../../components/Loading/LoadingButton";

export default function PopupConfirmSting({
  isShow,
  listReceipt,
  setConfirm,
  game_type,
}: {
  isShow: boolean;
  listReceipt: ListReceipt;
  setConfirm: any;
  game_type: string;
}) {
  const [listLeaderBoard, setListLeaderBoard] = useState<any>();
  const [listCampaign, setListCampaign] = useState<Campaign>();
  const [checkBothCampaign, setCheckBothCampaign] = useState();
  useEffect(() => {
    if (isShow) {
      localStorage.setItem("STATUS_CONFIRM", "TRUE");
    }
    let gameLeaderBoard = listReceipt?.rewards?.filter(
      (x: LuckyDrawList) => x.game_id < 0
    );
    const campaignSting: any = listReceipt?.rewards?.filter(
      (x: any) =>
        x?.campaign_type?.toLowerCase().includes("special") ||
        x?.campaign_type?.toLowerCase().includes("aqua")
    )[0];
    const campaignStingAndAqua: any = listReceipt?.rewards?.filter(
      (x: any) =>
        x?.campaign_type?.toLowerCase().includes("special") &&
        x?.campaign_type?.toLowerCase().includes("aqua")
    )[0];
    console.log(campaignStingAndAqua);
    setCheckBothCampaign(campaignStingAndAqua);
    setListCampaign(campaignSting?.campaign_data);
    setListLeaderBoard(gameLeaderBoard);
  }, [isShow]);
  let { token } = userLocal.get();
  setAuthorization(token);
  document.body.style.overflow = "unset";
  console.log(listReceipt);
  const navigation = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const handleConfirmGift = () => {
    setLoading(true);
    document.body.style.overflow = "unset";

    const params = {
      sale_id: listReceipt?.sale_id,
    };
    if (!isLoading) {
      homeServices
        .customerConfirmApi(params)
        .then((res) => {
          console.log(res);
          console.log(listReceipt);
          if (listReceipt.show_leader_board) {
            navigation(
              `/list-leader-board/${listReceipt.leader_board_campaign_id}`
            );
          } else {
            if (listReceipt.rewards.length === 1) {
              if (listReceipt.rewards[0]?.game_id === -1) {
                navigation(`/list-gift/3`);
              } else {
                gameLocal.set(listReceipt.rewards[0].game_data);
                const a = listReceipt.rewards[0].game_data;
                const campaign_id = listReceipt.rewards[0].campaign_id;
                const sale_id = listReceipt.sale_id;
                console.log(a);
                navigation(`/${game_type}/${campaign_id}/${sale_id}`);
                localStorage.setItem("LOCATION_STATUS_GAME", "TRUE");
              }
            } else {
              console.log("1");
              let gameFilter = listReceipt?.rewards?.filter(
                (x: LuckyDrawList) => x.game_id > 0
              );

              console.log(gameFilter);

              if (listReceipt.rewards.length === 0) {
                navigation("/exchange-point");
              } else {
                if (gameFilter?.length > 1) {
                  navigation("/games");
                } else {
                  const checkGame = gameFilter[0].game_data;
                  const checkCampaign = gameFilter[0].campaign_id;
                  const sale_id = listReceipt.sale_id;
                  navigation(`/${game_type}/${checkCampaign}/${sale_id}`);
                }
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          localStorage.removeItem("SALE_ID");
          setConfirm(false);
          setLoading(false);
        });
    }
  };
  const onRedirect = (game_id: string, campaign_id: number) => {
    const params = {
      sale_id: listReceipt?.sale_id,
    };
    console.log(`/${game_id}/${campaign_id}/${listReceipt?.sale_id}`);

    homeServices
      .customerConfirmApi(params)
      .then((res) => {
        console.log(res);

        navigation(`/${game_id}/${campaign_id}/${listReceipt?.sale_id}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const { hotline, zalo_link } = JSON.parse(
    localStorage.getItem("HOME_DATA_LOCAL") || "{}"
  );
  console.log(listCampaign);
  return (
    <div className="relative w-full">
      {isShow ? (
        <div className="w-full min-h-full background-result">
          <div
            className="w-full"
            style={{
              backgroundImage: `url(${listCampaign?.background_confirm_image_url})`,
              backgroundPosition: "center",
              backgroundSize: "100% 100%",
              width: "100%",
              minHeight: "100vh",
            }}
          >
            <div>
              {" "}
              <img src={listCampaign?.title_confirm_image_url} className="" />
            </div>
            <div
              className={`${
                game_type === "aqua_pickup" ? "-top-8" : ""
              } relative w-full popupGift__background`}
            >
              <div className="min-h-[450px]">
                {listReceipt?.rewards?.map((res, index) => {
                  console.log(res);
                  return (
                    <div className="flex flex-col w-full justify-center items-center">
                      {res?.lucky_draw_count > 0 ? (
                        <>
                          <div
                            className={`${
                              game_type === "aqua_pickup"
                                ? "w-80 h-80"
                                : "w-80 h-80"
                            } flex flex-col items-center justify-center`}
                            style={{
                              backgroundImage: `url(${listCampaign?.background_gift_image_url})`,
                              backgroundPosition: "cover",
                              backgroundSize: "100% 100%",
                            }}
                          >
                            <div
                              style={{
                                color:
                                  listCampaign?.quantity_number_prize_confirm_color,
                              }}
                              className={`${
                                game_type === "aqua_pickup"
                                  ? "-mt-12"
                                  : "-mt-10"
                              } flex justify-center items-center text-[#FF0202] font-semibold-so text-[90px]`}
                            >
                              +{res?.lucky_draw_count}
                              <div className="ml-1 flex items-center h-full">
                                <img
                                  src={
                                    game_type === "aqua_pickup"
                                      ? PLAY
                                      : PLAY_STING
                                  }
                                  className={`${
                                    game_type === "aqua_pickup"
                                      ? "w-6 -mt-[15px]"
                                      : "w-6"
                                  }  mt-4`}
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                color:
                                  game_type === "aqua_pickup"
                                    ? listCampaign?.quantity_number_prize_confirm_color
                                    : listCampaign?.campaign_text_details_color,
                              }}
                              className="-mt-7 text-white text-[20px] text-center font-semibold-so leading-6"
                            >
                              <>
                                Lượt xác định
                                <br /> trúng thưởng
                              </>
                            </div>
                          </div>
                          <div
                            className={`${
                              game_type === "aqua_pickup"
                                ? "hidden"
                                : "-mt-[75px]"
                            } relative font-semibold-so text-[20px]  text-center`}
                            style={{
                              backgroundImage: `url(${listCampaign?.label_campaign_image_url})`,
                              backgroundPosition: "cover",
                              backgroundSize: "100% 100%",
                              width: "90%",
                              height: "70px",
                            }}
                          >
                            <div className="absolute top-1/2 -translate-y-1/2 w-full">
                              <div
                                style={{
                                  // color: listCampaign?.programme_name_color,
                                  color:
                                    listCampaign?.quantity_number_prize_confirm_color,
                                }}
                                className="text-[#FDC715] uppercase font-semibold-so text-[12px]"
                              >
                                Chương trình
                              </div>
                              <div
                                style={{
                                  color:
                                    listCampaign?.campaign_text_details_color,
                                }}
                                className="text-white text-[16px] font-semibold-so w-full"
                              >
                                {res?.campaign_name}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  );
                })}
                {listReceipt?.rewards?.map((res, index) => (
                  <Fragment>
                    {res.gifts?.length > 0 && (
                      <div key={index} className="pb-80">
                        {res.gifts?.map((item: any, indexItem) => (
                          <>
                            <div className="relative top-32 left-1/2 -translate-x-1/2 border-im-point w-80 h-56 flex flex-col justify-center items-center">
                              {item.quantity > 0 ? (
                                <>
                                  <div className=" flex flex-col items-center justify-center text-[#ffffff]">
                                    <div className="flex justify-center items-center text-[#ffffff] font-semibold-so text-[90px]">
                                      +{item.quantity}
                                    </div>
                                    <div className="ml-1 mt-1 flex items-center h-full">
                                      <img
                                        src={item?.gift_square_image_url}
                                        className="w-28 -mt-8"
                                      />
                                    </div>
                                    <div className="mt-2 text-center font-semibold-so">
                                      {item?.gift_name}
                                    </div>
                                  </div>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item?.instructions_redeeming_rewards,
                                    }}
                                    className="font-light-so text-[#ffffff] text-[14px] mt-2 text-center"
                                  ></div>
                                  <div className=" font-semibold-so text-[20px] mt-2 text-center">
                                    <div className="text-[#FDC715] uppercase text-[12px]">
                                      Chương trình
                                    </div>
                                    <div className="text-[#ffffff] text-[16px] font-semibold-so">
                                      {res?.campaign_name}
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </>
                        ))}
                      </div>
                    )}
                  </Fragment>
                ))}
                {listReceipt?.rewards?.map((res, index: number) => (
                  <Fragment>
                    {res.point !== 0 && (
                      <>
                        <div className="flex mt-2 justify-center items-center text-[#FF0202] font-bold-so text-[80px]">
                          +{res.point}
                          <div className="ml-1">
                            <img src={COIN} className=" mt-4" />
                          </div>
                        </div>
                        <div className="text-white text-center -mt-5 font-semibold-so text-[20px]">
                          Pepcoin
                          <div
                            style={{
                              color: listCampaign?.programme_name_color,
                            }}
                            className="text-[#FDC715] uppercase text-[12px]"
                          >
                            Chương trình
                          </div>
                          <div className="text-[#ffffff] text-[20px]">
                            {res?.campaign_name}
                          </div>
                        </div>
                      </>
                    )}
                  </Fragment>
                ))}
                {listReceipt?.rewards?.map((res, index: number) => (
                  <Fragment>
                    {res.lucky_code_count > 0 && (
                      <>
                        <div className="flex -mt-5 flex-col w-full justify-center items-center">
                          <div
                            className="w-72 h-72 flex flex-col items-center justify-center"
                            style={{
                              backgroundImage: `url(${listCampaign?.background_gift_image_url})`,
                              backgroundPosition: "cover",
                              backgroundSize: "100% 100%",
                            }}
                          >
                            <div className="flex justify-center items-center text-[#FF0202] font-bold-so text-[70px]">
                              +{res?.lucky_code_count}
                              <div className="ml-1 flex items-center h-full">
                                <img
                                  src={CODE}
                                  className="w-10 mt-4 rounded-full"
                                />
                              </div>
                            </div>
                            <div className="-mt-5 text-center font-semibold-so text-[20px]">
                              Mã dự thưởng
                            </div>
                          </div>
                          <div className=" font-semibold-so text-[20px] -mt-10 text-center">
                            <div
                              style={{
                                color: listCampaign?.programme_name_color,
                              }}
                              className="text-[#FDC715] uppercase text-[12px]"
                            >
                              Chương trình
                            </div>
                            <div className="text-white text-[20px]">
                              {res?.campaign_name}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </Fragment>
                ))}
                {listLeaderBoard?.length > 0 && (
                  <>
                    {listLeaderBoard[0].lucky_program_content_details && (
                      <div className="relative top-5 w-10/12 left-1/2 -translate-x-1/2 flex justify-center rounded-xl border-code-confirm">
                        <div className="relative text-center py-3">
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                listLeaderBoard[0]
                                  .lucky_program_content_details,
                            }}
                            className="text-[14px]"
                          ></div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {listReceipt?.base_point !== 0 && (
                  <>
                    <div className="flex mt-5 flex-col w-full justify-center items-center">
                      <div
                        className=" w-72 h-72 flex flex-col items-center justify-center "
                        style={{
                          backgroundImage: `url(${listCampaign?.background_gift_image_url})`,
                          backgroundPosition: "cover",
                          backgroundSize: "100% 100%",
                        }}
                      >
                        {(listReceipt?.rewards?.length as number) !== 0 && (
                          <div className="top-1 font-light-italic-so relative text-white">
                            Và tích lũy thêm
                          </div>
                        )}{" "}
                        <div className="flex justify-center items-center text-[#FF0202] font-bold-so text-[70px]">
                          +{listReceipt.base_point}
                          <div className="ml-1 flex items-center h-full">
                            <img
                              src={COIN}
                              className="w-10 mt-4 rounded-full"
                            />
                          </div>
                        </div>
                        <div className="text-white uppercase text-[12px]">
                          Pepcoin
                        </div>
                      </div>
                      <div className="w-full font-semibold-so text-[20px] -mt-10 text-center">
                        <div
                          style={{
                            color: listCampaign?.programme_name_color,
                          }}
                          className="text-[#FDC715] uppercase text-[12px]"
                        >
                          Chương trình
                        </div>
                        <div className="relative uppercase text-white text-[20px] font-semibold-so w-full">
                          Tích Pepcoin Săn Ngay Quà Hot
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="h-40 w-full"></div>
            <div
              className="fixed w-full bottom-0 bg-[#FF0202]"
              style={{ backgroundColor: listCampaign?.join_button_text_color }}
            >
              <div className="flex justify-center px-5 w-full z-30">
                {isLoading ? (
                  <button></button>
                ) : (
                  <button
                    onClick={handleConfirmGift}
                    className="w-9/12 rounded-xl"
                  >
                    <img src={listCampaign?.label_continue_image_url} />
                  </button>
                )}
              </div>
              <div
                style={{
                  color: listCampaign?.campaign_text_details_color,
                }}
                className="text-white font-light-so text-[12px] w-full text-center mt-0 z-20"
              >
                {" "}
                Gọi điện cho Hotline{" "}
                <a
                  href={`tel:${hotline}`}
                  style={{
                    color: listCampaign?.quantity_number_prize_confirm_color,
                  }}
                  className="text-[#FDB516] font-bold-so"
                >
                  {" "}
                  {hotline}
                </a>{" "}
                để được hỗ trợ
              </div>
              <div>
                <img src={listCampaign?.footer_confirm_page_image_url} />
              </div>
            </div>

            <ContactPhoneZalo />
          </div>
        </div>
      ) : null}
    </div>
  );
}
