import { Fragment, useEffect, useState } from "react";
import { GameServices } from "../../../services/apiService/GameService";
import { LUOT_QUAY } from "../../../services/localService/localService";
import { useNavigate, useParams } from "react-router-dom";
import { GameDetailsModel } from "../../../models/GameModel";
import PopupOverlay from "../../../components/BackgroundPopup/PopupOverLay";
import PopupError from "../../../components/Popup/PopupError";
import "../../../assets/css/Sting.css";
import VIDEO from "../../../assets/image/Game/Aqua/aqua.mp4";
import PopupSpecialReceive from "../Sting/PopupSpecialReceive";
import { GiftWheelModel } from "../../../models/GiftModel";
import CLICK from "../../../assets/image/GuideTakePhoto/click.png";
import LIGHT from "../../../assets/image/Game/Aqua/light.png";

export default function MainPageAqua() {
  const [game, setBackgroundGame] = useState<GameDetailsModel>();
  const [dataCallUrbox, setDataCallUrbox] = useState<number>(0);
  const [win, setwin] = useState<GiftWheelModel>();
  const [disable, setDisable] = useState(false);
  const [video, setVideo] = useState(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [messError, setMessError] = useState<string>("Error");
  const [lineId, setLineId] = useState();
  const location_status_game = localStorage.getItem("LOCATION_STATUS_GAME");
  const [isPopupGuide, setIsPopupGuide] = useState(
    location_status_game === "TRUE" ? true : false
  );
  const { campaign_id, sale_id } = useParams<string>();
  const [isPopupGift, setIsPopupGift] = useState(false);

  useEffect(() => {
    getCalculator(sale_id, campaign_id);
    setTimeout(() => {
      setIsPopupGuide(false);
    }, 1500);
  }, []);

  const navigation = useNavigate();
  const handleChoose = () => {
    setDisable(true);
    if (!disable) {
      setVideo(true);
      document.body.style.overflow = "hidden";
      countDraw();
    }
  };
  const getCalculator = (
    sale_id: string | undefined,
    campaign_id: string | undefined
  ) => {
    const params = {
      sale_id: sale_id,
      campaign_id: campaign_id,
    };
    GameServices.CalculatorWheelApi(params)
      .then((res: any) => {
        setIsPopupGift(false);
        setVideo(false);
        setDisable(false);
        setIsLoading(false);
        console.log(res);
        if (res.remaining_draw === 0) {
          setIsError(true);
          setMessError("Bạn đã hết lượt chơi !!!");
        } else {
          setDataCallUrbox(res.remaining_draw);
          setBackgroundGame(res.lucky_draw_list.game_data);
          let itemWin = res.lucky_draw_list.list_lucky_draw[0].list_win[0];
          setLineId(itemWin?.line_id);
          console.log(itemWin);
          setwin(itemWin);
          localStorage.setItem(
            "GAME_DATA_LOCAL",
            JSON.stringify(res.lucky_draw_list.game_data)
          );
        }
      })
      .catch((err) => {
        setIsError(true);
        setMessError(err);
        console.log(err);
      });
  };
  const countDraw = async () => {
    const data = {
      sale_id: sale_id,
      campaign_id: campaign_id,
      line_id: lineId,
    };
    console.log(data);

    GameServices.CountDrawApi(data)
      .then((res: any) => {
        console.log(res);
        localStorage.setItem("CONFIRM", "CONFIRM");
        if (res.remaining_draw === 0) {
          const dataParams = {
            sale_id: sale_id,
            campaign_id: campaign_id,
          };
          GameServices.SendGiftApi(dataParams)
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        setDataCallUrbox(res.remaining_draw);
      })
      .catch((err) => {
        setIsError(true);
        setMessError(err);
        console.log(err);
      });
  };
  const handleClearPopup = () => {
    setIsPopupGuide(false);
  };
  const handleCloseError = () => {
    navigation("/games");
    setIsError(false);
  };
  const handleBack = () => {
    navigation("/");
  };
  const [isLoading, setIsLoading] = useState(false);

  const handleClosePopup = () => {
    localStorage.removeItem("CONFIRM");
    if (!isLoading) {
      setIsLoading(true);
      getCalculator(sale_id, campaign_id);
    }
    if (dataCallUrbox === 0) {
      navigation("/list-gift");
    }
  };
  const { hotline } = JSON.parse(
    localStorage.getItem("HOME_DATA_LOCAL") || "{}"
  );
  return (
    <Fragment>
      {video ? (
        <div className="stylevideo bg-[#F5F6FA] min-h-screen w-full">
          <video
            width="100%"
            playsInline
            preload="metadata"
            autoPlay
            disablePictureInPicture
            controls={false}
            style={{ opacity: 1 }}
            onEnded={() => {
              setIsPopupGift(true);
              console.log("ended");
            }}
          >
            <source src={VIDEO} type="video/mp4" />
          </video>
        </div>
      ) : (
        <div
          className=""
          style={{
            backgroundImage: `url(${game?.background_image_url})`,
            backgroundSize: "100% 100%",
            backgroundPosition: "cover",
            width: "100%",
            minHeight: "100vh",
          }}
        >
          <button
            className="absolute top-5 left-5 z-10"
            aria-label="buy product"
            onClick={handleBack}
          ></button>
          <PopupOverlay isShow={isPopupGuide}>
            <div onClick={handleClearPopup}>
              <div className="relative">
                <img
                  src={game?.game_items[0]}
                  className="w-6/12 relative left-1/2 -translate-x-1/2"
                />
                <div className="absolute top-2 w-full flex justify-center">
                  <img
                    src={game?.game_items[1]}
                    className="relative rotate-thunder w-7/12 animation-thunder"
                  />
                </div>
              </div>
              <div className="absolute bottom-14 left-[45%] -translate-x-[45%]">
                <img src={CLICK} alt="" className="w-48 click-animation" />
              </div>
              <div className="absolute text-[30px] -bottom-5 font-semibold-so text-white text-center w-full">
                Chạm để chơi
              </div>
            </div>
          </PopupOverlay>
          <div className="relative top-5 w-full">
            <img
              src={game?.title_image_url}
              className="w-9/12 relative left-1/2 -translate-x-1/2"
            />
            <div
              className="relative top-4 w-8/12 left-1/2 -translate-x-1/2"
              onClick={() => handleChoose()}
            >
              <img
                src={game?.game_items[0]}
                className="relative left-1/2 -translate-x-1/2 w-8/12"
              />
              <div>
                <img
                  src={LIGHT}
                  className="absolute top-0 left-1/2 w-8/12 -translate-x-1/2 animation-lightaqua"
                />
              </div>
            </div>
            <button
              className="relative top-4 left-1/2 -translate-x-1/2 py-1"
              style={{
                backgroundImage: `url(${game?.button_top_image_url})`,
                backgroundSize: "100% 100%",
                backgroundPosition: "contain",
                width: "250px",
                color: game?.button_text_color,
              }}
              onClick={() => handleChoose()}
            >
              <label className="relative -top-[3px] font-bold-so text-[18px]">
                {dataCallUrbox > 0 ? (
                  <>
                    Bạn có <span className="text-[25px]"> {dataCallUrbox}</span>{" "}
                    lượt
                    <div className="font-light-so text-[12px] -mt-1">
                      XÁC ĐỊNH TRÚNG THƯỞNG
                    </div>
                  </>
                ) : (
                  <div className="">Hết lượt chơi</div>
                )}
              </label>
            </button>
            <div className="mt-7">
              <div
                style={{
                  color: "#333333",
                }}
                className="text-white font-light-so text-[12px] w-full text-center mt-0 z-20"
              >
                {" "}
                Gọi điện cho Hotline{" "}
                <a
                  href={`tel:${hotline}`}
                  style={{
                    color: "#0013E9",
                  }}
                  className=" font-bold-so"
                >
                  {" "}
                  {hotline}
                </a>{" "}
                để được hỗ trợ
              </div>
            </div>
          </div>
          <PopupError
            labelBtn={"Đồng ý"}
            messError={messError}
            handleClose={handleCloseError}
            isShow={isError}
          />
        </div>
      )}
      <PopupSpecialReceive
        handleClosePopup={handleClosePopup}
        amountPlay={dataCallUrbox}
        gift={win!}
        isPopupGift={isPopupGift}
        isLoading={isLoading}
      />
    </Fragment>
  );
}
