import { HistoryBill } from "../../models/HistoryModel";

export default function ItemHistoryBill({ res }: { res: HistoryBill }) {
  return (
    <div className="mb-5">
      <div className="shadow-history grid grid-cols-12 rounded-2xl pt-3">
        <div className="col-span-3 flex justify-center items-center">
          <div className="w-[80px] h-[80px] flex justify-center">
            <img
              src={res?.receipt_image_url}
              loading="lazy"
              className="w-[80px] h-[80px] object-cover rounded-lg"
            />
          </div>
        </div>

        <div className="col-span-9 flex flex-col justify-between">
          <div className="text-[12px] font-light-so px-1">
            <div className="flex justify-between">
              {res?.receipt_no && <div>Số hóa đơn</div>}
              <div className="text-[#2D4E68] font-medium-so">
                {res?.receipt_no}
              </div>
            </div>
            <div className="grid grid-cols-12">
              <div className="col-span-4">Tên siêu thị</div>
              <div className="text-[#2D4E68] text-right font-medium-so col-span-8 text-reponsive">
                {res.store_name}
              </div>
            </div>
            <div className="flex justify-between">
              <div>Trạng thái</div>
              <div
                className={`${
                  (res.state === "done" && "text-[#219653]") ||
                  (res.state === "fail" && "text-[#ED3136]") ||
                  (res.state === "in_progress" && "text-[#5BC2DC]")
                }  font-medium-so`}
              >
                {res.status}
              </div>
            </div>
            <div className="flex justify-between">
              <div>Ngày tham gia</div>
              <div className="text-[#2D4E68] font-medium-so">
                {res.order_date}
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-1">
            <div className="bg-[#76DBF4] relative right-0 pl-[6px] w-[115px] rounded-tl-3xl rounded-br-2xl mt-0">
              <div className="bg-[#5BC2DC] font-light-so px-4 w-[110px] py-1 rounded-tl-3xl text-[14px] rounded-br-2xl text-[#F2FCFF] text-center">
                Chi tiết
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
