import BackgroundGame from "../../../components/BackgroundPopup/BackgroundGame";
import BG_GIFT from "../../../assets/image/Game/LuckyBox/bg-gift.png";
import TAMGIAC from "../../../assets/image/Game/LuckyBox/tamgiac.png";
import BUTTON_RECEIVE from "../../../assets/image/Game/LuckyBox/button-receive.png";
import FOOTER_BG_RECEIVE from "../../../assets/image/Game/LuckyBox/footer-bg-receive.png";
import "../../../assets/css/LuckyBox.css";
import { useEffect, useState } from "react";
import OverlayPopup from "../../../components/OverlayPopup/OverlayPopup";
import { useNavigate, useParams } from "react-router-dom";
import { GameDetailsModel } from "../../../models/GameModel";
import { GiftWheelModel } from "../../../models/GiftModel";
import { GameServices } from "../../../services/apiService/GameService";
import PopupError from "../../../components/Popup/PopupError";
import PopupOverlay from "../../../components/BackgroundPopup/PopupOverLay";
import CLICK from "../../../assets/image/GuideTakePhoto/click.png";

export default function LuckyBox() {
  const [disableBtn, setDisableBtn] = useState(false);
  const [animationCap, setAnimationCap] = useState(false);
  const [isOpenGift, setIsOpenGift] = useState(false);
  const [animationRotateGift, setAnimationRotateGift] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [refeshData, setRefeshData] = useState(false);
  const handleAction = () => {
    setDisableBtn(true);
    countDraw();
    setTimeout(() => {
      setAnimationCap(true);
      setTimeout(() => {
        setAnimationRotateGift(true);
        setTimeout(() => {
          setAnimationRotateGift(false);
          setIsOpenGift(true);
        }, 1500);
      }, 300);
    }, 2000);
  };

  const handleClose = () => {
    if (dataCallUrbox === 0) {
      navigation("/list-gift");
    }
    setRefeshData(true);
    setIsOpenGift(false);
    setDisableBtn(false);
    setAnimationCap(false);
    setAnimationRotateGift(false);
  };

  const [game, setBackgroundGame] = useState<GameDetailsModel>();
  const [dataCallUrbox, setDataCallUrbox] = useState<number>(0);
  const [win, setwin] = useState<GiftWheelModel>();
  const [isError, setIsError] = useState<boolean>(false);
  const [messError, setMessError] = useState<string>("Error");
  const [lineId, setLineId] = useState();
  const { campaign_id, sale_id } = useParams<string>();
  const location_status_game = localStorage.getItem("LOCATION_STATUS_GAME");
  const [isPopupGuide, setIsPopupGuide] = useState(
    location_status_game === "TRUE" ? true : false
  );

  useEffect(() => {
    getCalculator(sale_id, campaign_id);
    setTimeout(() => {
      setIsPopupGuide(false);
    }, 1500);
  }, []);
  useEffect(() => {
    if (refeshData) {
      getCalculator(sale_id, campaign_id);
    }
  }, [refeshData]);
  const navigation = useNavigate();

  const getCalculator = (
    sale_id: string | undefined,
    campaign_id: string | undefined
  ) => {
    const params = {
      sale_id: sale_id,
      campaign_id: campaign_id,
    };
    GameServices.CalculatorWheelApi(params)
      .then((res: any) => {
        setRefeshData(false);

        setIsLoading(false);
        console.log(res);
        if (res.remaining_draw === 0) {
          setIsError(true);
          setMessError("Bạn đã hết lượt chơi !!!");
        } else {
          setDataCallUrbox(res.remaining_draw);
          setBackgroundGame(res.lucky_draw_list.game_data);
          let itemWin = res.lucky_draw_list.list_lucky_draw[0].list_win[0];
          setLineId(itemWin?.line_id);
          console.log(itemWin);
          setwin(itemWin);
        }
      })
      .catch((err) => {
        setIsError(true);
        setMessError(err);
        console.log(err);
      });
  };
  const countDraw = async () => {
    setIsLoading(true);
    const data = {
      sale_id: sale_id,
      campaign_id: campaign_id,
      line_id: lineId,
    };
    console.log(data);
    if (!isLoading) {
      GameServices.CountDrawApi(data)
        .then((res: any) => {
          console.log(res);
          if (res.remaining_draw === 0) {
            const dataParams = {
              sale_id: sale_id,
              campaign_id: campaign_id,
            };
            GameServices.SendGiftApi(dataParams)
              .then((res) => {
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });
          }
          setDataCallUrbox(res.remaining_draw);
        })
        .catch((err) => {
          setIsError(true);
          setMessError(err);
          console.log(err);
        });
    }
  };
  const handleClearPopup = () => {
    localStorage.removeItem("LOCATION_STATUS_GAME");
    setIsPopupGuide(false);
  };
  const handleCloseError = () => {
    navigation("/games");
    setIsError(false);
  };
  return (
    <div>
      <BackgroundGame img={game?.background_image_url}>
        <div className="absolute top-0">
          <PopupOverlay isShow={isPopupGuide}>
            <div onClick={handleClearPopup} className="relative top-80">
              <div className="relative"></div>
              <div className="absolute bottom-0 left-1/2 -translate-x-1/2">
                <img src={CLICK} alt="" className="w-48 click-animation" />
              </div>
              <div className="absolute -bottom-5 font-semibold-so text-white text-center w-full">
                Chạm để chơi
              </div>
            </div>
          </PopupOverlay>
          <div className="relative top-20 w-full">
            <img
              src={game?.title_image_url}
              className="w-10/12 relative left-1/2 -translate-x-1/2"
            />
          </div>
          <div
            className="relative -top-[23vh]"
            style={{
              backgroundImage: `url(${game?.machine_table_image_url})`,
              backgroundSize: "100% 100%",
              backgroundPosition: "center",
              width: "100%",
              height: "80vh",
            }}
          >
            <div className="absolute bottom-[21%] w-full flex justify-center">
              <div
                className={`${
                  disableBtn ? "animation-boxstrong" : " animation-box"
                } relative w-10/12`}
              >
                <div className="relative top-10 z-10 ">
                  <img
                    src={game?.machine_header_image_url}
                    className={`${
                      animationCap ? "openCap" : ""
                    } relative z-10 left-1/2 -translate-x-1/2 w-9/12 `}
                  />
                </div>

                <div className="relative top-0">
                  <img
                    src={game?.machine_body_image_url}
                    className="relative z-0 left-1/2 -translate-x-1/2 w-10/12"
                  />
                  <div
                    className={`${
                      animationRotateGift ? "animate-showgift" : "hidden"
                    } absolute z-20 -top-20 left-1/2 -translate-x-1/2 `}
                  >
                    <div className="absolute text-white left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 z-50">
                      <div className="flex justify-center">
                        <img
                          src={win?.gift_square_image_url}
                          className="w-7/12"
                        />
                      </div>
                    </div>

                    <img
                      src={BG_GIFT}
                      className={`${
                        animationRotateGift ? "animate-rotategift" : ""
                      }`}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="fixed bottom-0">
              <img src={game?.machine_footer_image_url} />
              <div className="absolute left-1/2 -translate-x-1/2 -top-14">
                <div className="w-60 font-semibold-so leading-5 border-luckyBox rounded-full text-center bg-[#FAF4BF] py-2 px-2">
                  <span className="text-[18px]">
                    BẠN CÓ {dataCallUrbox} LƯỢT
                  </span>{" "}
                  <br />
                  <span className="text-[14px]">XÁC ĐỊNH TRÚNG THƯỞNG</span>
                </div>
                <div className="absolute -bottom-2 left-1/2 -translate-x-1/2">
                  <img src={TAMGIAC} />
                </div>
              </div>
              <button
                className="absolute top-3 left-1/2 -translate-x-1/2"
                onClick={handleAction}
              >
                <img
                  src={
                    !disableBtn
                      ? game?.button_top_image_url
                      : game?.button_bottom_image_url
                  }
                  className="w-48"
                />
              </button>
            </div>
          </div>
        </div>
      </BackgroundGame>
      <OverlayPopup isShow={isOpenGift}>
        <div className="relative z-30 top-0 w-screen -left-5">
          <div className="absolute w-screen">
            <img src={BG_GIFT} className="animate-rotatereceive w-screen" />
          </div>
          <div className="relative">
            <div className="relative top-0 z-0">
              <img src={game?.gift_frame_url} className="" />
              <div className="absolute top-20 flex justify-center w-full">
                <img
                  src={
                    win?.no_gift
                      ? game?.title_no_gift_image_url
                      : game?.title_result_image_url
                  }
                  className="w-9/12"
                />
              </div>
              <div className="absolute top-36 w-full h-11/12 flex justify-center">
                <div className="bg-white w-8/12  containerLuckyBox__background relative top-6 max-h-[400px] z-10 rounded-xl overflow-hiden">
                  <div className="flex flex-col animation-show-luckygift items-center relative containerLuckyBox__background-list rounded-2xl pt-3 box-border z-30 h-full">
                    <img src={win?.gift_square_image_url} className="w-6/12" />
                    <div className="text-[22px] leading-6 mt-3 font-semibold-so w-52 text-center text-[#1B2F6A]">
                      {win?.no_gift ? "" : win?.gift_name}
                    </div>
                    <div
                      className="text-center text-[12px] mt-2 pb-24 font-light-so px-2 text-[#4F4F4F]"
                      dangerouslySetInnerHTML={{
                        __html: win?.instructions_redeeming_rewards as string,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute -bottom-14 w-full overflow-hidden body-hide">
              <div className="flex justify-center">
                <img src={FOOTER_BG_RECEIVE} className="w-11/12" />
                <button
                  className="absolute top-1/2 -translate-y-1/2 flex justify-center"
                  onClick={handleClose}
                >
                  <img src={BUTTON_RECEIVE} className="w-11/12" />
                  <div className="absolute top-1/2 -translate-y-[85%] font-semibold-so text-[#0B1FC0]">
                    {dataCallUrbox > 0 ? (
                      <>Còn {dataCallUrbox} lượt chơi</>
                    ) : (
                      <>Quà của tôi</>
                    )}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </OverlayPopup>
      <PopupError
        labelBtn={"Đồng ý"}
        messError={messError}
        handleClose={handleCloseError}
        isShow={isError}
      />
    </div>
  );
}
